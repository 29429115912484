/*
 * @Author: Matthew Auld
 * @Date: 2019-03-03 11:10:39
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-03-04 02:54:26
 * Copyright 2019 JumpButton North - All rights reserved.
 */
import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import LandingPage from "./pages/landing-page";
import NavBar from "./components/navbar";
import AboutMePage from "./pages/about-me-page";
import $ from "jquery";
import PortfolioPage from "./pages/portfolio-page";

class App extends Component {
	_isMounted = false;

	state = {
		page: ""
	};

	componentDidMount() {
		this._isMounted = true;
		let pg = this.props.location.pathname.replace(/\//gi, "");
		if (this._isMounted)
			this.setState(
				{
					page: pg === "" ? "home" : pg
				},
				() => {
					$("body").attr("class", this.state.page);
				}
			);
	}

	componentWillReceiveProps(nextProps) {
		let pg = nextProps.location.pathname.replace(/\//gi, "");
		if (this._isMounted && this.state.page !== pg)
			this.setState(
				{
					page: pg === "" ? "home" : pg
				},
				() => {
					$("body").attr("class", this.state.page);
				}
			);
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		return (
			<div id="web-content" className={"" + this.state.page}>
				<NavBar />
				<Switch>
					<Route exact path="/" component={LandingPage} />
					<Route exact path="/about-me" component={AboutMePage} />
					<Route exact path="/portfolio" component={PortfolioPage} />
				</Switch>
			</div>
		);
	}
}

export default withRouter(App);
