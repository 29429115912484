/*
 * @Author: Matthew Auld
 * @Date: 2019-03-03 15:24:42
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-03-04 08:18:56
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./about-me-page.scss";
import AboutBlock from "../components/about-block";
import mysql_logo from "../mysql-5.svg";
import $ from "jquery";
import {
	faHtml5,
	faCss3Alt,
	faJs,
	faReact,
	faPhp
} from "@fortawesome/free-brands-svg-icons";
import photoshop from "../photoshop.svg";
import illustrator from "../illustrator.svg";
import csharp from "../c-sharp.svg";
import java from "../java.svg";
import nodejs from "../nodejs.svg";
import sass from "../sass.svg";

class AboutMePage extends Component {
	_isMounted = false;
	__animationObject = [];
	state = {
		showProgress: false
	};

	componentDidMount() {
		this._isMounted = true;

		$('[data-toggle="tooltip"]').tooltip();

		$(".zeroValue").each((e, p) => {
			setTimeout(() => {
				$(p).removeClass("zeroValue");
			}, 2000 + 250 * e);
		});

		setTimeout(() => {
			if (this._isMounted) {
				$('[data-toggle="tooltip"]').tooltip();
				this.setState({
					showProgress: true
				});
			}
		}, 1750);
		if (this.iOS()) {
			$("#profile-photo")
				.removeClass("polygon-clip-hexagon")
				.addClass("clip-circle");
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getSVGImg(icon, color) {
		color = color.replace(/#/g, "%23");

		return (
			'data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="' +
			icon.prefix +
			'" data-icon="' +
			icon.iconName +
			'" class="svg-inline--fa ' +
			icon.iconName +
			' fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ' +
			icon.icon[0] +
			" " +
			icon.icon[1] +
			'"><path fill="' +
			color +
			'" d="' +
			icon.icon[4] +
			'"></path></svg>'
		);
	}

	iOS() {
		var iDevices = [
			"iPad Simulator",
			"iPhone Simulator",
			"iPod Simulator",
			"iPad",
			"iPhone",
			"iPod"
		];

		if (!!navigator.platform) {
			while (iDevices.length) {
				if (navigator.platform === iDevices.pop()) {
					return true;
				}
			}
		}

		return false;
	}

	render() {
		return (
			<div id="about-me" className="page">
				<div className="container">
					<div className="row">
						<div className="col-12" id="about-header">
							<h1>
								<div className="animated fadeInLeft delay-0_5s">
									About
								</div>
								<br className="d-block d-sm-none" />
								<div className="animated fadeInRight bold delay-0_5s">
									Matthew Auld
								</div>
							</h1>
						</div>
					</div>
					<div
						className="row animated slideInUp"
						id="personal-traits"
					>
						<div className="col-6 col-md-3 pb-3">
							<AboutBlock
								icon={["far", "tachometer-fastest"]}
								title="Optimized"
								text="Fast load times and lag free user interaction is my top priority."
								step={1}
							/>
						</div>
						<div className="col-6 col-md-3 pb-3">
							<AboutBlock
								icon={["fal", "desktop"]}
								title="Responsive"
								text="My html &amp; css layouts work on any device of any size. Many thanks goes to Bootstrap for making this easier."
								step={2}
							/>
						</div>
						<div className="col-6 col-md-3 pb-3">
							<AboutBlock
								icon={["fal", "lightbulb-on"]}
								title="Fresh Ideas"
								text="I constantly try to create a better user experience with my UX/UI work."
								step={3}
							/>
						</div>
						<div className="col-6 col-md-3 pb-3">
							<AboutBlock
								icon={["fal", "monitor-heart-rate"]}
								title="Dynamic"
								text="Websites don't have to be plain jane. I love to bring them to live with animations and dynamic components."
								step={4}
							/>
						</div>
					</div>
					<div className="row pt-5">
						<div className="col-12 col-md-4" id="personal-details">
							<svg className="clip-svg">
								<defs>
									<clipPath
										id="polygon-clip-hexagon"
										clipPathUnits="objectBoundingBox"
									>
										<polygon points="0.5 0, 1 0.25, 1 0.75, 0.5 1, 0 0.75, 0 0.25" />
									</clipPath>
								</defs>
							</svg>
							<img
								id="profile-photo"
								src="/img/matthew-auld.jpg"
								className="polygon-clip-hexagon animated bounceInUp"
								alt="Matthew's Face"
							/>
							<h3 className="cursive">Who Am I?</h3>
							<p>
								My name is Matthew Auld and I've been
								programming since 56k dial-up just became widely
								spread in my hometown of Niagara Falls, Ontario.
								I started learning from good old dusty library
								books at Niagara Falls Public Library and from
								that; it sparked this fire within to keep going.
								I couldn't stop learning about programming. From
								PHP to C#, I've dabbled in it all. My passion
								for computers has never faded but only grew. My
								favourite part is creating user experiences that
								people remember. There's nothing more soothing
								than a Sleek design with smooth transitions.
							</p>
						</div>
						<div
							className="col-12 col-md-8"
							id="professional-skills"
						>
							<div className="row skill-set">
								<div className="col-12" id="skill-title">
									<h2 className="cursive">Skills/Assets</h2>
									<p className="lead">
										Here is a short list of
										skills/technlogies I use and a
										percentage of just how fluent I am with
										each.
									</p>
								</div>
								<div className="col-6  d-flex align-items-center justify-content-center icon-skills">
									<img
										src={this.getSVGImg(faHtml5, "#FFA500")}
										alt="HTML5"
										data-toggle="tooltip"
										data-placement="right"
										title="HTML5"
									/>
									<div className="progress">
										<div
											className="progress-bar bg-success zeroValue"
											role="progressbar"
											style={{ width: "97%" }}
										>
											<span>HTML5 (97%)</span>
										</div>
									</div>
								</div>
								<div className="col-6  d-flex align-items-center justify-content-center icon-skills">
									<img
										src={this.getSVGImg(faReact, "#00d8ff")}
										alt="ReactJS"
										data-toggle="tooltip"
										data-placement="right"
										title="ReactJS"
									/>

									<div className="progress">
										<div
											className="progress-bar bg-success zeroValue"
											role="progressbar"
											style={{ width: "97%" }}
										>
											<span>ReactJS (97%)</span>
										</div>
									</div>
								</div>
								<div className="col-6  d-flex align-items-center justify-content-center icon-skills">
									<img
										src={this.getSVGImg(
											faCss3Alt,
											"#1695E7"
										)}
										alt="CSS3"
										data-toggle="tooltip"
										data-placement="right"
										title="CSS3"
									/>
									<div className="progress">
										<div
											className="progress-bar bg-success zeroValue"
											role="progressbar"
											style={{ width: "93%" }}
										>
											<span>CSS3 (93%)</span>
										</div>
									</div>
								</div>
								<div className="col-6  d-flex align-items-center justify-content-center icon-skills">
									<img
										src={sass}
										alt="Sass"
										data-toggle="tooltip"
										data-placement="right"
										title="Sass"
									/>
									<div className="progress">
										<div
											className="progress-bar bg-success zeroValue"
											role="progressbar"
											style={{ width: "91%" }}
										>
											<span>Sass (91%)</span>
										</div>
									</div>
								</div>
								<div className="col-6  d-flex align-items-center justify-content-center icon-skills">
									<img
										src={this.getSVGImg(faJs, "#F0DB4F")}
										alt="JavaScript"
										data-toggle="tooltip"
										data-placement="right"
										title="JavaScript (ES5,ES6)"
									/>
									<div className="progress">
										<div
											className="progress-bar bg-success zeroValue"
											role="progressbar"
											style={{ width: "90%" }}
										>
											<span>JavaScript (90%)</span>
										</div>
									</div>
								</div>
								<div className="col-6  d-flex align-items-center justify-content-center icon-skills">
									<img
										src={mysql_logo}
										alt="MySQL"
										data-toggle="tooltip"
										data-placement="right"
										title="MySQL Database Query Language"
									/>

									<div className="progress">
										<div
											className="progress-bar bg-success zeroValue"
											role="progressbar"
											style={{ width: "89%" }}
										>
											<span>MySQL (89%)</span>
										</div>
									</div>
								</div>
								<div className="col-6  d-flex align-items-center justify-content-center icon-skills">
									<img
										src={this.getSVGImg(faPhp, "#787CB5")}
										alt="PHP"
										data-toggle="tooltip"
										data-placement="right"
										title="PHP 5.0-7.2"
									/>

									<div className="progress">
										<div
											className="progress-bar bg-success zeroValue"
											role="progressbar"
											style={{ width: "83%" }}
										>
											<span>PHP (83%)</span>
										</div>
									</div>
								</div>
								<div className="col-6  d-flex align-items-center justify-content-center icon-skills">
									<img
										src={nodejs}
										alt="Node.JS"
										data-toggle="tooltip"
										data-placement="right"
										title="Node.JS"
									/>

									<div className="progress">
										<div
											className="progress-bar bg-success zeroValue"
											role="progressbar"
											style={{ width: "82%" }}
										>
											<span>Node.JS (82%)</span>
										</div>
									</div>
								</div>
								<div className="col-6  d-flex align-items-center justify-content-center icon-skills">
									<img
										src={photoshop}
										alt="Photoshop"
										data-toggle="tooltip"
										data-placement="right"
										title="Photoshop - Slicing PSD into function app/website."
									/>

									<div className="progress">
										<div
											className="progress-bar bg-success zeroValue"
											role="progressbar"
											style={{ width: "79%" }}
										>
											<span>PhotoShop (79%)</span>
										</div>
									</div>
								</div>

								<div className="col-6  d-flex align-items-center justify-content-center icon-skills">
									<img
										src={csharp}
										alt="C#"
										data-toggle="tooltip"
										data-placement="right"
										title="C# - CSharp in Visual Studio."
									/>

									<div className="progress">
										<div
											className="progress-bar bg-success zeroValue"
											role="progressbar"
											style={{ width: "62%" }}
										>
											<span>CSharp (C#) (62%)</span>
										</div>
									</div>
								</div>
								<div className="col-6  d-flex align-items-center justify-content-center icon-skills">
									<img
										src={java}
										alt="Java"
										data-toggle="tooltip"
										data-placement="right"
										title="Java - Mostly used for server or game development."
									/>

									<div className="progress">
										<div
											className="progress-bar bg-success zeroValue"
											role="progressbar"
											style={{ width: "64%" }}
										>
											<span>Java (64%)</span>
										</div>
									</div>
								</div>
								<div className="col-6  d-flex align-items-center justify-content-center icon-skills">
									<img
										src={illustrator}
										alt="Illustrator"
										data-toggle="tooltip"
										data-placement="right"
										title="Illustrator - Working with vector for the web."
									/>

									<div className="progress">
										<div
											className="progress-bar bg-success zeroValue"
											role="progressbar"
											style={{ width: "56%" }}
										>
											<span>Illustrator (56%)</span>
										</div>
									</div>
								</div>
								<div className="col-12 py-4 border-top mt-2">
									<h2 className="cursive">Awards</h2>
									<img
										src="/portfolio/awards.jpg"
										alt="Philly Geek Awards"
										style={{
											maxWidth: "50%",
											margin: "auto"
										}}
										className="d-block"
									/>
									<br />
									<p>
										My proudest achievements thus far in the
										tech world. Dev Product of the Year for
										the app I built all by myself and
										Multimedia Project of the Year for my
										team and I made animated educational
										videos for YouTube where I did all the
										voice-over work.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12 py-4 text-center">
							<Link
								to="/portfolio"
								onClick={() => {
									$("body").scrollTop(0);
								}}
								className="btn btn-primary"
							>
								Take A Look At My Portfolio
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AboutMePage;
