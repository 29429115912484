/*
 * @Author: Matthew Auld
 * @Date: 2019-03-03 11:10:35
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-03-03 21:59:35
 * Copyright 2019 JumpButton North - All rights reserved.
 */
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/js/bootstrap.bundle";
import "animate.css/animate.css";
import "./fonts.scss";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import {
	faBars,
	faChevronRight,
	faDesktop,
	faLightbulbOn,
	faMonitorHeartRate,
	faDatabase
} from "@fortawesome/pro-light-svg-icons";

import { faTachometerFastest } from "@fortawesome/pro-regular-svg-icons";

import {
	faHtml5,
	faCss3Alt,
	faJs,
	faReact,
	faPhp
} from "@fortawesome/free-brands-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";

library.add(
	faBars,
	faChevronRight,
	faTachometerFastest,
	faDesktop,
	faLightbulbOn,
	faMonitorHeartRate,
	faHtml5,
	faCss3Alt,
	faJs,
	faDatabase,
	faReact,
	faPhp
);

ReactDOM.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>,
	document.getElementById("root")
);

serviceWorker.unregister();
