/*
 * @Author: Matthew Auld
 * @Date: 2019-03-03 15:53:29
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-03-04 00:42:34
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class AboutBlock extends Component {
	state = { state: null, icon: null };

	componentDidMount() {
		this.setState({
			icon: this.props.hasOwnProperty("icon") ? this.props.icon : null
		});
	}

	render() {
		return (
			<div className="about-block">
				<div
					className="hex-wrap anim-scroll animated flipInX delay-0_75s"
					style={
						this.props.hasOwnProperty("step")
							? {
									animationDelay:
										1 + this.props.step * 0.25 + "s"
							  }
							: {}
					}
				>
					<div className="hexagon">
						{this.state.icon !== null && (
							<FontAwesomeIcon icon={this.state.icon} />
						)}
					</div>
				</div>
				<div
					className="label bold animated bounceIn"
					style={
						this.props.hasOwnProperty("step")
							? {
									animationDelay:
										1 + this.props.step * 0.25 + "s"
							  }
							: {}
					}
				>
					{this.props.hasOwnProperty("title") && this.props.title}
				</div>
				<div
					className={
						"label text animated fadeInUp" +
						(this.props.hasOwnProperty("step") ? "" : " delay-1s")
					}
					style={
						this.props.hasOwnProperty("step")
							? {
									animationDelay:
										1 + this.props.step * 0.25 + "s"
							  }
							: {}
					}
				>
					{this.props.hasOwnProperty("text") && this.props.text}
				</div>
			</div>
		);
	}
}

export default AboutBlock;
