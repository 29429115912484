/*
 * @Author: Matthew Auld
 * @Date: 2019-03-03 11:20:30
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-03-03 20:44:45
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./landing-page.scss";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class LandingPage extends Component {
	state = {};
	render() {
		return (
			<div id="landing-page" className="page">
				<header>
					<div className="tint">
						<div id="jumbo-name" className="container">
							<div className="text-white animated zoomInUp">
								<h1>
									Hello!
									<br className="d-block d-sm-none" />
									<span>
										I'm <strong>Matthew Auld</strong>
									</span>
								</h1>
								<p>
									I'm an award winning Full-Stack Web and App
									Developer.
								</p>
							</div>

							<Link
								to="/about-me"
								id="btn-view-work"
								className="animated zoomInDown"
								onClick={e => {
									e.preventDefault();
									$("header").addClass("fadeOut");
									setTimeout(() => {
										console.log("Animated");
										console.log(this.props);
										this.props.history.push("/about-me");
									}, 250);
								}}
							>
								Learn More About Me{" "}
								<FontAwesomeIcon
									icon={["fal", "chevron-right"]}
									className="ml-3"
								/>
							</Link>
						</div>
					</div>
				</header>
			</div>
		);
	}
}

export default LandingPage;
