/*
 * @Author: Matthew Auld
 * @Date: 2019-03-04 02:51:59
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-03-04 07:49:28
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import "./portfolio-page.scss";

class PortfolioPage extends Component {
	_projects = [];
	_isMounted = false;
	state = {
		projects: []
	};

	componentDidMount() {
		this._isMounted = true;

		this.addProject(
			"1WorkBook - Online Cloud Service",
			"Dec 2011 - Feb 2013 - Discontinued",
			"Built an entire online cloud storage system with ajax uploader and custom made cluster storage system.",
			"/portfolio/1workbook.png",
			"1WorkBook",
			"php,html,javascript,java,mysql,css"
		);

		this.addProject(
			"JumpButton V3",
			"Jan 2013",
			"Built an entire gaming social network from scratch.",
			"/portfolio/jumpbutton-version-3.png",
			"JumpButton Studio",
			"php,html,javascript,java,mysql,css",
			"https://www.jumpbuttonstudio.com/"
		);

		this.addProject(
			"JumpButton V4",
			"Apr 2014",
			"Redesign the website using existing social network back-end.",
			"/portfolio/jumpbutton-version-4.png",
			"JumpButton Studio",
			"php,html,javascript,java,mysql,css",
			"https://www.jumpbuttonstudio.com/"
		);

		this.addProject(
			"Organize.me - Contact and Bookmark storage",
			"Apr 2014 - Discontinued",
			"Built an entire online contact and bookmark storage system with real-time searching/filtering.",
			"/portfolio/organize-me.png",
			"Organize.me",
			"php,html,javascript,backbone.js,underscore.js,java,mysql,css"
		);

		this.addProject(
			"JumpButton V5",
			"Mar 2016",
			"Dropped social network aspect going corporate",
			"/portfolio/jumpbutton-version-5.png",
			"JumpButton Studio",
			"php,html,javascript,java,mysql,css",
			"https://www.jumpbuttonstudio.com/"
		);

		this.addProject(
			"Classic Spas - Tub Manager Dashboard",
			"Jul 2016 - Jan 2017 - Private Use",
			"Design and build tools from scratch to manage the hottub inventory and restoration schedule.",
			"/portfolio/classic-spas-dashboard.png",
			"ClassicSpas",
			"php,html,css,mysql,javascript"
		);

		this.addProject(
			"JumpButton V6",
			"Mar 2016",
			"Formal Redesign, coded from scratch.",
			"/portfolio/jumpbutton-version-6.png",
			"JumpButton Studio",
			"php,html,javascript,java,mysql,css",
			"https://www.jumpbuttonstudio.com/"
		);

		this.addProject(
			"Ward Pressure Washing - Company Website",
			"Nov 2016",
			"Fix up an existing wordpress website and update it all.",
			"/portfolio/ward-pressure-washing.png",
			"WardPressureWashing",
			"bootstrap,js,html5,css3,mysql,php,wordpress",
			"http://www.jtgsystems.com/"
		);

		this.addProject(
			"Pinestreet Dental - Company Website",
			"Jul 2018",
			"Built entirely from stratch using Node.JS and ExressJS",
			"/portfolio/pinestreetdental-ca.png",
			"PineStreet Dental",
			"bootstrap,nodejs,expressjs,html5,css3,mysql",
			"http://www.pinestreetdental.ca/"
		);

		this.addProject(
			"JTG Systems - Company Website",
			"Aug 2018",
			"Redesign and build the entire website from scratch.",
			"/portfolio/jtg-systems.png",
			"JTG Systems",
			"bootstrap,nodejs,expressjs,html5,css3,mysql",
			"http://www.jtgsystems.com/"
		);

		this.addProject(
			"My Portfolio",
			"Mar 2019",
			"Built entirely from stratch using ReactJS",
			"/portfolio/matthew-auld.png",
			"Matthew Auld Portfolio",
			"bootstrap,reactjs,html5,css3"
		);

		this.setState({
			projects: this._projects
		});
	}

	addProject(title, dateString, description, thumb_path, alt, tech, url) {
		this._projects.push({
			title,
			date: dateString,
			description,
			thumb_path,
			alt,
			tech: tech.split(","),
			url
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}
	render() {
		return (
			<div id="portfolio-page" className="page">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h1 className="text-center">
								Projects I've Worked On
							</h1>
							<hr />
						</div>
					</div>
					<div className="row">
						{[...this.state.projects].reverse().map((p, k) => (
							<div className="col-12 col-md-4 project" key={k}>
								<div className="card h-100">
									<img
										src={p.thumb_path}
										className="card-img-top"
										alt={p.alt}
										onClick={() => {
											$("#preview-modal").modal("show");
											$("#preview-modal img").attr(
												"src",
												p.thumb_path
											);
										}}
									/>
									<div className="card-body">
										<h5 className="card-title">
											{p.title}
										</h5>
										<h6 className="card-subtitle mb-2 text-muted">
											{p.date}
										</h6>
										<p className="card-text">
											{p.description}
										</p>
										{p.url !== undefined && p.url !== null && (
											<a
												href={p.url}
												className="btn btn-primary btn-sm"
												target="_blank"
												rel="noreferrer noopener"
											>
												Visit Website
											</a>
										)}
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
				<div
					className="modal"
					tabIndex="-1"
					role="dialog"
					id="preview-modal"
				>
					<div className="modal-dialog modal-xl" role="document">
						<div className="modal-content">
							<div className="modal-body">
								<img src="" className="w-100" alt="" />
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-secondary"
									data-dismiss="modal"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default PortfolioPage;
