/*
 * @Author: Matthew Auld
 * @Date: 2019-03-03 11:51:30
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2019-03-04 02:53:35
 * Copyright 2019 JumpButton North - All rights reserved.
 */

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import logo from "../logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";

class NavBar extends Component {
	_isMounted = false;
	state = {
		activeTab: "/"
	};

	componentDidMount() {
		this._isMounted = true;

		this.setState({
			activeTab: this.props.location.pathname
		});
	}

	componentWillReceiveProps(nextProps) {
		if (
			this._isMounted &&
			this.state.activeTab !== nextProps.location.pathname
		)
			this.setState({
				activeTab: nextProps.location.pathname
			});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		return (
			<nav id="mainNav" className="navbar navbar-expand-lg">
				<div className="container">
					<Link to="/" className="navbar-brand">
						<img src={logo} alt="logo" />
						<div className="name">
							<div>
								<span className="first">Matthew</span>{" "}
								<span className="last">Auld</span>
								<i>Full Stack Developer</i>
							</div>
						</div>
					</Link>
					<button
						className="navbar-toggler text-white"
						data-toggle="collapse"
						data-target="#navbarSupportedContent"
					>
						<FontAwesomeIcon icon={["fal", "bars"]} />
					</button>

					<div
						className="collapse navbar-collapse"
						id="navbarSupportedContent"
					>
						<ul className="navbar-nav ml-auto">
							<li
								className={
									"nav-item" +
									(this.state.activeTab === "/"
										? " active"
										: "")
								}
							>
								<Link
									className="nav-link"
									to="/"
									onClick={() => {
										if (
											$(
												"#navbarSupportedContent"
											).hasClass("show")
										)
											$(".navbar-toggler").click();
									}}
								>
									Home
								</Link>
							</li>

							<li
								className={
									"nav-item" +
									(this.state.activeTab === "/about-me"
										? " active"
										: "")
								}
							>
								<Link
									className="nav-link"
									to="/about-me"
									onClick={() => {
										if (
											$(
												"#navbarSupportedContent"
											).hasClass("show")
										)
											$(".navbar-toggler").click();
									}}
								>
									About Me
								</Link>
							</li>
							<li
								className={
									"nav-item" +
									(this.state.activeTab === "/portfolio"
										? " active"
										: "")
								}
							>
								<Link
									className="nav-link"
									to="/portfolio"
									onClick={() => {
										if (
											$(
												"#navbarSupportedContent"
											).hasClass("show")
										)
											$(".navbar-toggler").click();
									}}
								>
									Portfolio
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		);
	}
}

export default withRouter(NavBar);
